'use client';

import clsx from 'clsx';
import React from 'react';
import useAuth from '@/contexts/AuthContext';
import { useMutation } from '@tanstack/react-query';
import { subscribeNewsletterFn } from '@/queries/account';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import i18next from 'i18n';
import { CMS } from '@/types/CMS';
import Button from './Button';
import TextInput from './TextInput';

interface INewsLetterBannerProps {
    anchor?: string;
    data: CMS.Section.SectionsNewsletterComponent;
}
const NewsletterEmailOnlySchema = Yup.object().shape({
    email_address: Yup.string().email(i18next.t('error-text.invalid-email')).required(i18next.t('error-text.required')),
});

const NewsletterSchema = Yup.object().shape({
    email_address: Yup.string().email(i18next.t('error-text.invalid-email')).required(i18next.t('error-text.required')),
    full_name: Yup.string().required(i18next.t('error-text.required')).test('includes-space', i18next.t('error-text.full-name-required'), (value) => value.includes(' ')),
});

const NewsletterBanner = (props: INewsLetterBannerProps) => {
    const { anchor, data: { text, title, button, NewsletterType, variant, background } } = props;
    const { user } = useAuth();

    const updateAddressMutation = useMutation({
        mutationFn: subscribeNewsletterFn
    });
    const isWhiteBackground = background === CMS.Section.SectionsNewsletterComponent.BackgroundEnum.White;
    const isDarkBackground = background === CMS.Section.SectionsNewsletterComponent.BackgroundEnum.Dark;
    const isTextureBackground = background === null || background === CMS.Section.SectionsNewsletterComponent.BackgroundEnum.Texture;

    return (
        <div
            id={anchor}
            className={clsx('px-[30px] py-[50px] bg-cover bg-no-repeat bg-center', {
                'text-main bg-white': isWhiteBackground,
                'text-white': !isWhiteBackground,
                'bg-black': isDarkBackground
            })}
            style={!isTextureBackground ? {} : { backgroundImage: "url('/assets/blue_texture.jpeg')" }}>
            <div className={clsx('flex flex-col mx-auto', {
                'md:flex-row': variant !== CMS.Section.SectionsNewsletterComponent.VariantEnum.EmailOnly
            })}>
                <div className={clsx('w-full flex justify-center items-center', {
                    'md:w-1/2': variant !== CMS.Section.SectionsNewsletterComponent.VariantEnum.EmailOnly
                })}>
                    <div className={clsx('z-10', {
                        'max-w-[433px]': variant !== CMS.Section.SectionsNewsletterComponent.VariantEnum.EmailOnly,
                        'max-w-[800px] text-center': variant === CMS.Section.SectionsNewsletterComponent.VariantEnum.EmailOnly
                    })}>
                        <h3 className={clsx('font-heading text-4xl mb-4', {
                            'mb-6': variant === CMS.Section.SectionsNewsletterComponent.VariantEnum.EmailOnly
                        })}>
                            {title}
                        </h3>
                        <span>
                            {text}
                        </span>
                    </div>
                </div>
                <div className={clsx('w-full flex flex-col justify-center items-center mt-12', {
                    'md:w-1/2 md:mt-0': variant !== CMS.Section.SectionsNewsletterComponent.VariantEnum.EmailOnly,
                })}>
                    <Formik
                        initialValues={{
                            full_name: user ? `${user.first_name || ''} ${user.last_name || ''}`.trim() : '',
                            email_address: user?.email_address || ''
                        }}
                        validationSchema={variant === CMS.Section.SectionsNewsletterComponent.VariantEnum.EmailOnly ? NewsletterEmailOnlySchema : NewsletterSchema}
                        onSubmit={async (values, { resetForm }) => {
                            try {
                                await updateAddressMutation.mutateAsync({
                                    email_address: values.email_address,
                                    full_name: variant === CMS.Section.SectionsNewsletterComponent.VariantEnum.EmailOnly ? undefined : values.full_name,
                                    newsletter: NewsletterType
                                });
                                toast.success(i18next.t('success-notification.newsletter-subscribed'));
                                resetForm();
                                // eslint-disable-next-line no-empty
                            } catch (error) {

                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                                className={clsx('w-full', {
                                    'max-w-[800px]': variant === CMS.Section.SectionsNewsletterComponent.VariantEnum.EmailOnly,
                                    'md:w-[unset]': variant !== CMS.Section.SectionsNewsletterComponent.VariantEnum.EmailOnly
                                })}>
                                <div className={clsx('w-full flex flex-col justify-center items-center font-heading capitalize space-y-6', {
                                    'md:w-[284px]': variant !== CMS.Section.SectionsNewsletterComponent.VariantEnum.EmailOnly
                                })}>
                                    {variant !== CMS.Section.SectionsNewsletterComponent.VariantEnum.EmailOnly && (
                                        <>
                                            <TextInput
                                                dark={!isWhiteBackground}
                                                type="text"
                                                inputPlaceholder={i18next.t('input-text.full-name')} // show isnide
                                                name="full_name"
                                                errored={errors.full_name && touched.full_name}
                                                value={values.full_name}
                                                wrapperClassName="w-full"
                                                onChange={handleChange}
                                            />
                                            {errors.full_name && touched.full_name ? (
                                                <div className="font-secondary text-[0.8rem] text-red">
                                                    {errors.full_name}
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                    <TextInput
                                        dark={!isWhiteBackground}
                                        type="text"
                                        name="email_address"
                                        errored={errors.email_address && touched.email_address}
                                        value={values.email_address}
                                        wrapperClassName="w-full"
                                        onChange={handleChange}
                                        inputPlaceholder={i18next.t('input-text.email')}
                                    />
                                    {errors.email_address && touched.email_address ? (
                                        <div className="font-secondary text-[0.8rem] text-red">
                                            {errors.email_address}
                                        </div>
                                    ) : null}
                                </div>
                                <div className={clsx('w-full !mt-6', {
                                    'md:w-[284px]': variant !== CMS.Section.SectionsNewsletterComponent.VariantEnum.EmailOnly
                                })}>
                                    <Button light={isDarkBackground || isTextureBackground} type="submit" gradient="bg-gradient-blue3" fullWidth size="md" loading={isSubmitting}>
                                        {button}
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>

        </div>
    );
};

export default NewsletterBanner;
