'use client';

import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import Link from 'next/link';

export type INotificationBannerProps = {
    text: string;
    backgroundColor?: string;
    link?: string;
};

const NotificationBanner = (props: INotificationBannerProps) => {
    const { text, backgroundColor, link } = props;
    const [show, setShow] = useState<boolean>(true);

    const content = (
        <AnimatePresence>
            {show && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{ backgroundColor }}
                    className={clsx(
                        'w-full text-center  py-2 text-sm text-white font-secondary relative bg-main'
                    )}
                >
                    <span className="font-secondary text-[1rem]" dangerouslySetInnerHTML={{ __html: text }} />
                    <XMarkIcon
                        className="h-7 w-7 text-white absolute top-[5px] cursor-pointer right-[5px]"
                        aria-hidden="true"
                        onClick={(e) => {
                            e.preventDefault();
                            setShow(false);
                        }}
                    />
                </motion.div>
            )
            }
        </AnimatePresence>
    );

    if (link) {
        return <Link href={link}>{content}</Link>;
    }

    return content;
};

export default NotificationBanner;
