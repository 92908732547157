'use client';

import React from 'react';
import { Item } from '@/api';

import Carousel from 'react-multi-carousel';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import Container from '../Layout/Container';
import ProductTile from './ProductTile';

import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1440 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 1440, min: 1080 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1080, min: 720 },
        items: 3
    },
    smallTablet: {
        breakpoint: { max: 720, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

const ButtonsGroup = ({ next, previous, ...rest }: any) => {
    const { carouselState: { currentSlide, slidesToShow, totalItems } } = rest;
    return totalItems > slidesToShow && (
        <>
            {currentSlide > 0 &&
                <button
                    type="button"
                    className="absolute left-[-2rem] top-0 bottom-0 m-auto hidden md:block"
                    onClick={previous}
                >
                    <ChevronLeftIcon className="text-grey3 w-[20px] h-[26px]" />
                </button>
            }
            {(totalItems - currentSlide) > slidesToShow &&
                <button
                    type="button"
                    className="absolute right-[-2rem] top-0 bottom-0 m-auto hidden md:block"
                    onClick={next}
                >
                    <ChevronRightIcon className="text-grey3 w-[20px] h-[26px]" />
                </button>
            }
        </>
    );
};

const ItemsCarousel = (props: { anchor?: string; title?: string, items: Item[] }) => {
    const { anchor, items, title } = props;

    return (
        <Container className="my-10">
            {title && <h3 id={anchor} className="font-heading text-xxl mb-4 md:smb-12 text-center w-full">{title}</h3>}
            <div className="mx-auto relative">
                {items.length === 1 ? (
                    <div className="max-w-[263px] mx-auto">
                        <ProductTile data={items[0]} />
                    </div>
                ) :
                    (
                        <Carousel arrows={false} customButtonGroup={<ButtonsGroup />} rewindWithAnimation rewind renderButtonGroupOutside renderDotsOutside ssr={false} autoPlay showDots responsive={responsive} className="center-items-carousel" itemClass="mx-[0px] mb-[50px]" dotListClass="pt-[20px]">
                            {items.map((item) => <ProductTile key={item.id} data={item} />)}
                        </Carousel>
                    )
                }

            </div>
        </Container>
    );
};

export default ItemsCarousel;
