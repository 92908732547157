'use client';

import React, { useMemo } from 'react';
import Link from 'next/link';
import { CMS } from '@/types/CMS';
import { useMobile } from '../../hooks/useMobile';

interface IFullWidthImageProps {
    anchor?: string;
    data: CMS.Section.SectionsFullWidthImageComponent;
}

const FullWidthImage = (props: IFullWidthImageProps) => {
    const { anchor, data: { image, mobileImage = null, Link: linkHref, text } } = props;
    const isMobile = useMobile();

    const content = useMemo(() => {
        return (
            <div className="relative">
                <img id={anchor} alt="" className="w-full h-full" src={isMobile && mobileImage ? mobileImage.data?.attributes.url : image.data?.attributes.url} />
                {text && (
                    <div className="absolute inset-0">
                        <div className="text-center flex items-center justify-center h-full">
                            <div dangerouslySetInnerHTML={{ __html: text }} />
                        </div>
                    </div>
                )}
            </div>
        );
    }, [anchor, image.data?.attributes.url, isMobile, mobileImage, text]);

    if (linkHref && image) {
        return (
            <Link id={anchor} href={linkHref} prefetch={false} className="no-hover">
                {content}
            </Link>
        );
    }
    return image ? content : null;
};

export default FullWidthImage;
